import { makeStyles, SuaveContainer } from '@admitkard/ccl';
import { BannerRenderer } from '@admitkard/common/Onboarding';
import { LoginRegisterInner } from '@admitkard/common/RegisterModal';
import { getQuestionsApi } from '@admitkard/common/useUserFields/userFieldsApi';
import { transformPafData } from '@admitkard/common/utils';
import { env } from '@admitkard/uiutils/env';
import pushToDataLayer from '@admitkard/uiutils/gTagDataLayer';
import { auth } from '@admitkard/uiutils/http';
import { LoginRegisterContext } from 'context/context';
import Router from 'next/router';
import { ParsedUrlQuery } from 'querystring';
import React, { useContext, useEffect, useState } from 'react';
import { handleGetCount } from '../../Apis';
import { QuestionRenderer } from './QuestionRenderer';
import { QuestionConfig } from 'types';
import { setUserField } from './questionRenderingEngineApi';
import { pafDataToPayload, queryToPafData } from 'components/ListingEngine/utils';
import { Analytics } from '@admitkard/uiutils/analytics/events';

// This function runs only on the server side
export async function getStaticProps() {
  // Instead of fetching your `/api` route you can call the same
  // function directly in `getStaticProps`
  const initialCount = await handleGetCount();

  // Props returned will be passed to the page component
  return { props: { initialCount } };
}

interface QuestionRenderingEngineProps {
  pafConfig: QuestionConfig;
  initialCount?: Record<string, string | number>;
  urlParams: ParsedUrlQuery;
  gaLevelEventKey: string;
  gaIdPrefix: string;
  defaultDirectionURL: string;
  externalData?: Record<string, any>;
}

const useStyles = makeStyles(
  () => ({
    questionRenderingEngineBackground: {
      display: 'grid',
      placeItems: 'center',
      margin: '3rem 0rem',
      maxWidth: '1700px',
      width: '100vw',
      backgroundImage: 'url("https://assets.admitkard.com/images/paf/bg.webp")',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      '@media only screen and (max-width: 1199px)': {
        width: '100%',
        background: 'none',
      },
      '@media only screen and (max-width: 459px)': {
        margin: 0,
        height: 'auto',
      },
    },
    questionRenderingEngineContainer: {
      display: 'flex',
      justifyContent: 'center',
      gap: '1rem',
      maxWidth: '70rem',
      width: '100%',
      '@media screen and (max-width: 991px)': {
        flexDirection: 'column',
      },
      fontFamily: 'Poppins',
    },
    loginContainer: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
      backgroundImage: 'url("https://assets.admitkard.com/images/paf/bg.webp")',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
    },
    loginContainerInner: {
      width: '100%',
      ['@media screen and (min-width:992px) and (max-width:1199px)']: {
        maxWidth: '55rem',
      },
      ['@media screen and (min-width:768px) and (max-width:991px)']: {
        maxWidth: '42rem',
      },
      ['@media screen and (min-width:576px) and (max-width:767px)']: {
        maxWidth: '32.375rem',
      },
      ['@media screen and (min-width:460px) and (max-width:575px)']: {
        maxWidth: '28.75rem',
      },
      ['@media screen and (min-width:380px) and (max-width:459px)']: {
        maxWidth: '100%',
        placeContent: 'start',
      },
      ['@media screen and (max-width:379px)']: {
        maxWidth: '100%',
        placeContent: 'start',
      },
    },
  }),
  { name: 'QuestionRenderingEngine' }
);

const QuestionRenderingEngine: React.FunctionComponent<QuestionRenderingEngineProps> = (props) => {
  const pafConfig = props.pafConfig;
  const classes = useStyles();
  const extractedQueryData = extractQueryData();
  const [currentPage, setCurrentPage] = useState(0);
  const [pafData, setPafData] = useState<Record<string, any>>({
    ...extractedQueryData,
    isInitial: false,
  });
  const totalPages = pafConfig?.pageConfig?.pages?.length - 1;

  const [userFieldsQuestions, setUserFieldsQuestions] = useState<Record<string, any>>({});
  const { setRedirectUrl, setHandleRedirectionOutsideLogin, isLoggedInCtx, setGlobalPafConfig } =
    useContext(LoginRegisterContext);
  const [initialLoggedIn, setInitialLoggedIn] = useState(false);

  const localStorageKey = `paf_${props.gaLevelEventKey}`;

  const [dataValues, setDataValues] = useState<Record<string, string | number>>({
    courseCount: props?.initialCount?.courses || 0,
    universityCount: props?.initialCount?.universities || 0,
  });
  const [showLogin, setShowLogin] = useState(pafConfig?.loginBefore ? true : false);

  const handlePAFQuestionChange = (dataObject?: Record<string, any>) => {
    dataObject && setDataValues({ ...dataValues, ...dataObject });
    localStorage.setItem(localStorageKey, JSON.stringify(pafData));
  };

  /**
   * This useEffect restrict the user form hitting back button while
   * filling the PAF by displaying a warning dialog.
   */
  useEffect(() => {
    let canExit = totalPages === currentPage;

    if (showLogin) {
      canExit = true;
    }

    function handleBeforeUnload(event: any) {
      event.preventDefault();
      const confirmationMessage = '\\o/';
      // Gecko + IE
      (event || window.event).returnValue = confirmationMessage;
      // Safari, Chrome, and other WebKit-derived browsers
      return confirmationMessage;
    }

    if (canExit) {
      removeEventListener('beforeunload', handleBeforeUnload);
    } else {
      addEventListener('beforeunload', handleBeforeUnload);
    }

    return () => {
      removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [currentPage, showLogin]);

  useEffect(() => {
    setGlobalPafConfig(pafConfig);

    //! set initial filters
    if (Object.keys(extractedQueryData).length > 0) {
      setPAFDataAndCache(extractQueryData);
    } else {
      getLocalStorageData();
    }

    getQuestionsApi().then((response) => {
      setUserFieldsQuestions(response);
    });
    setHandleRedirectionOutsideLogin(true);
    setRedirectionUrl();
    const token = auth.getToken();
    if (token) {
      setInitialLoggedIn(true);
    }
    Analytics.track('level_start', { pafConfig: pafConfig?.id || 'DEFAULT_PAF_FLOW' });
    pushToDataLayer({
      event: 'level_start',
      data: {
        ['level_name']: props.gaLevelEventKey,
        ['level_id']: `${props.gaIdPrefix}_${pafConfig?.id}`,
      },
    });
    pushToDataLayer({
      event: 'level_up',
      data: {
        level: currentPage + 1,
      },
    });
  }, []);

  const setPAFDataAndCache = (data: Record<string, any>) => {
    localStorage.setItem(localStorageKey, JSON.stringify(data));
  };

  function extractQueryData() {
    let data = {};
    const extractedQuery = queryToPafData(props.urlParams ?? {});
    if (Object.keys(extractedQuery).length > 0) {
      const firstQuestionKey = pafConfig?.pageConfig?.pages[0];
      if (firstQuestionKey) {
        const dataOfFirstQuestion = extractedQuery[firstQuestionKey];
        if (dataOfFirstQuestion) {
          data = { [firstQuestionKey]: dataOfFirstQuestion };
        }
      }
    }
    return data;
  }

  useEffect(() => {
    if (Object.keys(pafData).length >= 1) {
      if (props.gaLevelEventKey === 'course_listing') {
        const query = pafDataToPayload(pafData);
        handleGetCount(query).then((count: any) => {
          if (Object.keys(query).length) {
            handlePAFQuestionChange({ courseCount: count?.courses, universityCount: count?.universities });
          }
        });
      } else {
        handlePAFQuestionChange();
      }
    }
  }, [pafData]);

  const getLocalStorageData = () => {
    const paf = localStorage.getItem(localStorageKey);
    if (paf) {
      setPafData(JSON.parse(paf));
    }
  };

  const pushToDataLayerPromise = async () => {
    return pushToDataLayer({
      event: 'generate_lead',
      data: {
        method: 'otp',
      },
    });
  };

  function getIntakeId() {
    const intakeSlug = pafData['INTAKE_SELECT']?.slug;
    const intakes = props.externalData?.intakes;

    if (Array.isArray(intakes)) {
      const intake = intakes.find((value) => value?.name === intakeSlug);
      return intake?._id as string;
    }
    return undefined;
  }

  function getSearchableCountry() {
    return pafData['SEARCHABLE_COUNTRY_SELECT']?.label;
  }

  function getCurrentCityId() {
    return pafData['SEARCHABLE_CURRENT_CITY_SELECT'];
  }

  const submitPafData = async () => {
    const tempPAFData = { ...pafData };
    const intakeId = getIntakeId();
    if (intakeId) {
      tempPAFData['intakeId'] = intakeId;
    }
    const searchableCountrySelect = getSearchableCountry();
    if (searchableCountrySelect) {
      tempPAFData['COUNTRY_SELECT'] = searchableCountrySelect;
    }

    const searchableCurrentCitySelect = getCurrentCityId();
    if(searchableCurrentCitySelect){
      tempPAFData['SEARCHABLE_CURRENT_CITY_SELECT'] = searchableCurrentCitySelect;
    }
    // send data to student profile
    const formattedPafData = transformPafData(tempPAFData, userFieldsQuestions);
    await setUserField({ studentOnboarded: true, ...formattedPafData });

    // push data to dataLayer for analytics
    await pushToDataLayerPromise();

    //push data to mix panel for analytics
    Analytics.track(
      'generate_lead',{method: 'otp'},
    );
  };

  const setRedirectionUrl = () => {
    const redirectTo = props.defaultDirectionURL;

    const query = Router.query;
    const returnTo = query.return_to ? decodeURIComponent(query.return_to as string) : undefined;
    let configRedirectionUrl = pafConfig?.redirectionUrl ? pafConfig.redirectionUrl : redirectTo;
    if (returnTo) {
      configRedirectionUrl = returnTo;
    }
    if (configRedirectionUrl.includes('/student')) {
      if ((env.ENV === 'development' || env.ENV === 'dev') && window.location.hostname.includes('localhost')) {
        configRedirectionUrl = `${`http://localhost:4001`}${configRedirectionUrl}`;
      } else {
        configRedirectionUrl = `${env.UI_URL}${configRedirectionUrl}`;
      }
    }
    setRedirectUrl(configRedirectionUrl);
    return configRedirectionUrl;
  };

  useEffect(() => {
    if (!initialLoggedIn) {
      if (isLoggedInCtx) {
        if (pafConfig.hasLogin) {
          if (!pafConfig.loginBefore) {
            const redirectionUrl = setRedirectionUrl();
            if (pafConfig.shouldSubmitPafData) {
              submitPafData().then(() => {
                window.location.href = redirectionUrl;
              });
            } else {
              window.location.href = redirectionUrl;
            }
          } else {
            setShowLogin(false);
          }
        }
      }
    }
  }, [isLoggedInCtx]);

  return !showLogin ? (
    <SuaveContainer>
      <div className={classes.questionRenderingEngineBackground}>
        <div className={classes.questionRenderingEngineContainer}>
          <BannerRenderer
            dataValues={(props.gaLevelEventKey === 'course_listing' && dataValues) || {}}
            bannerConfig={pafConfig?.bannerConfig as any}
            currentPage={currentPage}
            totalPages={totalPages}
          />
          <QuestionRenderer
            pafData={pafData}
            pageConfig={pafConfig?.pageConfig}
            currentPage={currentPage}
            setData={handlePafData}
            goToNext={goToNext}
            goToPrevious={goToPrevious}
            urlParams={props.urlParams}
            externalData={{
              countries: props?.externalData?.countries,
            }}
          />
        </div>
      </div>
    </SuaveContainer>
  ) : (
    <SuaveContainer>
      <div className={classes.loginContainer}>
        <div className={classes.loginContainerInner}>
          <LoginRegisterInner
            heading={"Let's get started"}
            bannerBackground={env.getAssetUrl(`images/generic_banner.svg`)}
            LoginRegisterContext={LoginRegisterContext}
            loginBannerConfig={pafConfig?.signInConfig?.bannerConfig}
          />
        </div>
      </div>
    </SuaveContainer>
  );

  function goToPrevious() {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
      clearLastQuestion(currentPage);

      pushToDataLayer({
        event: 'level_down',
        data: {
          level: currentPage,
        },
      });
    }
  }

  function clearLastQuestion(currentQuestionIndex: number) {
    const currentPageKey: string = pafConfig?.pageConfig?.pages[currentQuestionIndex];

    if (currentPageKey) {
      handlePafData(currentPageKey, undefined);
    }
  }

  function goToNext() {
    if (pafConfig?.pageConfig?.pages?.length && currentPage < pafConfig?.pageConfig?.pages?.length - 1) {
      setCurrentPage(currentPage + 1);
      pushToDataLayer({
        event: 'level_up',
        data: {
          level: currentPage + 2,
        },
      });
    }
    if (currentPage === pafConfig?.pageConfig?.pages?.length - 1) {
      Analytics.track('level_complete', { pafConfig: pafConfig?.id || 'DEFAULT_PAF_FLOW' });
      const redirectionUrl = setRedirectionUrl();
      if (pafConfig.hasLogin) {
        if (initialLoggedIn) {
          window.location.href = redirectionUrl;
        } else if (!pafConfig.loginBefore) {
          setShowLogin(true);
        } else {
          if (pafConfig.shouldSubmitPafData) {
            submitPafData().then(() => {
              window.location.href = redirectionUrl;
            });
          } else {
            window.location.href = redirectionUrl;
          }
        }
      } else {
        if (pafConfig.shouldSubmitPafData) {
          submitPafData().then(() => {
            window.location.href = redirectionUrl;
          });
        } else {
          window.location.href = redirectionUrl;
        }
      }
    }
  }

  function handlePafData(pageKey: string, data?: any) {
    const tempData = { ...pafData };

    if (data) {
      tempData[pageKey] = data;
    } else {
      delete tempData[pageKey];
    }

    setPafData(tempData);
  }
};

export default QuestionRenderingEngine;
