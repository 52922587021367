import { env } from '@admitkard/uiutils/env';
import { http } from '@admitkard/uiutils/http';
import { sprintf } from 'sprintf-js';


const LOGIN_API = {
  MY_STUDENT_PROFILE: `${env.SMS_API}auth/student-profile/my-profile/paf?platform=%(platform)s`
};

export const setUserField = (newUserField: Record<string, string | number | any | string[] | boolean>) => {
  newUserField = { ...newUserField };
  if (newUserField.areaOfStudy) {
    newUserField.departments = newUserField.specialization || [];
    newUserField.specialization = newUserField.areaOfStudy || [];
    delete newUserField.areaOfStudy;
  }
  const platform = navigator.appVersion.indexOf('Mobile') === -1 ? 'desktop-website' : 'mobile-website';
  return http.patch(sprintf(LOGIN_API.MY_STUDENT_PROFILE, {platform}), newUserField).then((response) => response.data);
};
